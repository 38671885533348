import React, { useState, useEffect } from "react";
import classes from "./Selftest1.module.css";
import Navbar from "../Navbar/Navbar.js";
import { updateInput } from "./formUtil.js";
import WebcamRecorder from "./circ-webcam.js";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";

const Selftest1 = () => {
  const navigate = useNavigate();
  const [isRecording, setRecording] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [isModelLoading, setModelLoading] = useState(false);
  const [huntToken, setHuntToken] = useState(false);
  const location = useLocation();
  const [t, sett] = useState("");

  const url = window.location.href;

  // Check if 'token=' is present in the URL
  const isTokenInUrl = url.includes("token");

  const findtokens = async (token) => {
    try {
      const response = await axios.get(`https://iterve.aivot.ai/token`, {
        params: { token },
      });
      console.log("res" + response.data.token);
      console.log(
        `https://iterve.aivot.ai/selftest/token=${response.data.token}`
      );
      localStorage.setItem("accno", "enduser");
    } catch (e) {
      console.log(e);
      window.alert("invalid link");
      navigate("/registerUser");
    }
  };

  useEffect(() => {
    if (huntToken) {
      const t = location.pathname.split("/").pop();

      const token = t.slice(6);
      sett(token);
      console.log("t", t, token);
      findtokens(token);
    }
  }, [huntToken]);

  useEffect(() => {
    window.scroll(0, 0);

    console.log(location.state);

    const t = location.pathname.split("/").pop();

    const token = t.slice(6);

    console.log(token);

    if (location.state !== "fromApolloMember") {
      console.log("token hunting is initiated");
      setHuntToken(true);
    }
  }, [location.state]);

  // Function to handle start of recording
  const startRecording = (e) => {
    if (isRecording) return;
    if (!isChecked) {
      window.alert("Please agree to the privacy policy");
      return;
    }
    const formData = updateInput(e);

    if (!formData) return;
    setModelLoading(performance.now());
    // setJsonData(details);
    setRecording(true);
  };

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  return (
    <div className={classes.container} id="content">
      <Navbar />
      <div className={classes.pageHeading}>Vitals measurement</div>
      <div className={classes.contentLayout}>
        <div className={classes.alertDiv}>
          Note: Before the video recording starts, please make sure the room is
          properly lit, especially your face. Also, make sure that no backlight
          is shining directly into the camera.
        </div>
        <div className={classes.mainContent}>
          <div className={classes.detailsContainer}>
            <div className={classes.formRow}>
              <div className={classes.formElement}>
                <div className={classes.inputLabel}>
                  Name:<span className={classes.requiredStar}>*</span>
                </div>
                <input
                  required
                  autoComplete="off"
                  id="name"
                  className={classes.formInput}
                  placeholder="Enter your name"
                />
              </div>
              <div className={classes.formElement}>
                <div className={classes.inputLabel}>
                  Email ID:
                  {location.state !== "fromApolloMember" ? (
                    <span className={classes.requiredStar}>*</span>
                  ) : (
                    ""
                  )}
                </div>
                <input
                  autoComplete="off"
                  id="email"
                  className={classes.formInput}
                  placeholder="Enter your email"
                  required={location.state !== "fromApolloMember"}
                />
              </div>
            </div>
            <div className={classes.formRow}>
              <div className={classes.formElement}>
                <div className={classes.inputLabel}>
                  Mobile Number:<span className={classes.requiredStar}>*</span>
                </div>
                <input
                  required
                  autoComplete="off"
                  id="phoneNumber"
                  type="number"
                  className={classes.formInput}
                  placeholder="Enter your mobile number"
                />
              </div>
              <div className={classes.formElement}>
                <div className={classes.inputLabel}>
                  Age:<span className={classes.requiredStar}>*</span>
                </div>
                <input
                  required
                  autoComplete="off"
                  id="age"
                  className={classes.formInput}
                  placeholder="Enter your age (in years)"
                ></input>
              </div>
            </div>
            <div className={classes.formRow}>
              <div className={classes.formElement}>
                <div className={classes.inputLabel}>
                  Weight:<span className={classes.requiredStar}>*</span>
                </div>
                <input
                  required
                  autoComplete="off"
                  id="weight"
                  type="number"
                  className={classes.formInput}
                  placeholder="Enter your weight (in kilograms)"
                  step="0.01"
                  min="20"
                  max="200"
                  onBlur={(e) => {
                    const weight = parseFloat(e.target.value);
                    if (weight < 20 || weight > 200) {
                      alert("Please enter a weight between 20 and 200 kg.");
                      e.target.value = "";
                      e.target.focus();
                    }
                  }}
                ></input>
              </div>
              <div className={classes.formElement}>
                <div className={classes.inputLabel}>
                  Height:<span className={classes.requiredStar}>*</span>
                </div>
                <input
                  required
                  autoComplete="off"
                  id="height"
                  type="number"
                  className={classes.formInput}
                  placeholder="Enter your height (in meters)"
                  step="0.01"
                  min="1"
                  max="2.2"
                  onBlur={(e) => {
                    const height = parseFloat(e.target.value);
                    if (height < 1 || height > 2.2) {
                      alert("Please enter a height between 1 and 2.2 meters.");
                      e.target.value = "";
                      e.target.focus();
                    }
                  }}
                ></input>
              </div>
            </div>
            <div className={classes.formRow}>
              <div className={classes.formElement}>
                <div className={classes.inputLabel}>
                  Gender:<span className={classes.requiredStar}>*</span>
                </div>
                <select id="gender" name="Gender" className={classes.formInput}>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                </select>
              </div>
              <div className={classes.formElement}>
                <div className={classes.inputLabel}>
                  Diabetic ?<span className={classes.requiredStar}>*</span>
                </div>
                <select
                  id="diabetic"
                  name="Diabetic"
                  className={classes.formInput}
                >
                  <option value="no">No</option>
                  <option value="yes">Yes</option>
                </select>
              </div>
            </div>
            <div className={classes.formRow}>
              <div className={classes.formElement}>
                <div className={classes.inputLabel}>
                  Activity factor:
                  <span className={classes.requiredStar}>*</span>
                </div>
                <select
                  id="activityFactor"
                  name="activity_factor"
                  className={classes.formInput}
                >
                  <option value="1.2">Sedentary</option>
                  <option value="1.375">Lightly active</option>
                  <option value="1.55">Moderately active</option>
                  <option value="1.725">Very active</option>
                  <option value="1.9">Extra active</option>
                </select>
              </div>
              <div className={classes.submitSec}>
                <button
                  className={
                    isChecked ? classes.recordBtn : classes.recordBtnDisabled
                  }
                  onClick={startRecording}
                  id="record-btn"
                >
                  Record Video
                </button>
              </div>
            </div>
          </div>

          <div className={classes.image}>
            <WebcamRecorder
              startRecording={isRecording}
              setRecording={setRecording}
              setLoading={setModelLoading}
              token={t}
              isChecked={isChecked}
            />
            {/* <img src={face} alt="Face landmarks"></img> */}
            <div className={classes.signoutSec}>
              {!isTokenInUrl && window.innerWidth >= 768 ? (
                <button
                  onClick={() => {
                    setRecording(false);
                    navigate("/reports1");
                  }}
                  className={classes.signout}
                >
                  Back
                </button>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
        <div className={classes.tncContainer}>
          <div className={classes.disclaimer}>
            Your video and picture is not stored in our database; our system
            extracts only RGB and rPPG from camera processing and uses it to
            derive your health vitals.
          </div>
          <label className={classes.checkboxLabel}>
            <input
              type="checkbox"
              checked={isChecked}
              onChange={handleCheckboxChange}
              className={classes.checkbox}
            />
            I agree to share my data for research and training purposes.
          </label>
        </div>
        {!isTokenInUrl && window.innerWidth < 768 ? (
          <button
            onClick={() => {
              setRecording(false);
              navigate("/reports1");
            }}
            className={classes.back}
          >
            Back
          </button>
        ) : (
          <></>
        )}
      </div>
      {isModelLoading && (
        <div className={classes.loadingOverlay}>
          <div className={classes.loadingText}>Please wait...</div>
        </div>
      )}
    </div>
  );
};

export default Selftest1;
