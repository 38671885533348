import React, { useState } from "react";

const Form2 = ({
  formData,
  updateInput,
  startRecording,
  classes,
  isChecked,
}) => {
  const handleInputChange = (e) => {
    // updateInput(e);
  };

  const [selectedOptionsHyperTension, setSelectedOptionsHyperTension] =
    useState([]);

  const [selectedOptionsDiabeteic, setSelectedOptionsDiabeteic] = useState([]);
  const [selectedOptionsMedication, setSelectedOptionsMedication] = useState(
    []
  );

  // Function to handle option selection
  const handleOptionClickHyperTension = (option) => {
    if (selectedOptionsHyperTension.includes(option)) {
      // If option is already selected, deselect it
      setSelectedOptionsHyperTension(
        selectedOptionsHyperTension.filter((item) => item !== option)
      );
    } else {
      // If option is not selected, select it
      setSelectedOptionsHyperTension([...selectedOptionsHyperTension, option]);
    }
  };

  const handleOptionClickDiabetic = (option) => {
    if (selectedOptionsDiabeteic.includes(option)) {
      // If option is already selected, deselect it
      setSelectedOptionsDiabeteic(
        selectedOptionsDiabeteic.filter((item) => item !== option)
      );
    } else {
      // If option is not selected, select it
      setSelectedOptionsDiabeteic([...selectedOptionsDiabeteic, option]);
    }
  };

  const handleOptionClickMedication = (option) => {
    if (selectedOptionsMedication.includes(option)) {
      // If option is already selected, deselect it
      setSelectedOptionsMedication(
        selectedOptionsMedication.filter((item) => item !== option)
      );
    } else {
      // If option is not selected, select it
      setSelectedOptionsMedication([...selectedOptionsMedication, option]);
    }
  };

  return (
    <>
      <div className={classes.formRow}>
        <div className={classes.formElement}>
          <div className={classes.inputLabel}>
            Recent weight changed?
            <span className={classes.requiredStar}>*</span>
          </div>
          <select
            id="weightChanged"
            name="weightChanged"
            className={classes.formInput}
            onChange={handleInputChange}
          >
            <option value="No">No</option>
            <option value="Yes">Yes</option>
          </select>
        </div>
        <div className={classes.formElement}>
          <div className={classes.inputLabel}>
            Meal time elapsed?<span className={classes.requiredStar}>*</span>
          </div>
          <select
            id="mealTimeElapsed"
            name="mealTimeElapsed"
            className={classes.formInput}
            onChange={handleInputChange}
          >
            <option value="4 Hr">4 Hr</option>
            <option value="2 Hr">2 Hr</option>
            <option value="6 Hr">6 Hr</option>
            <option value="8 Hr">8 Hr</option>
          </select>
        </div>
      </div>
      <div className={classes.formRow}>
        <div className={classes.formElement}>
          <div className={classes.inputLabel}>
            Hypertension?<span className={classes.requiredStar}>*</span>
          </div>
          <select
            id="hypertension"
            name="hypertension"
            className={classes.formInput}
            onChange={handleInputChange}
          >
            <option value="No">No</option>
            <option value="Yes">Yes</option>
          </select>
        </div>
        <div className={classes.formElement}>
          <div className={classes.inputLabel}>
            Family History of Hypertension?
          </div>
          <div className={classes.optionSec}>
            <button
              className={
                selectedOptionsHyperTension.includes("Father")
                  ? classes.selected
                  : classes.notSelected
              }
              onClick={() => handleOptionClickHyperTension("Father")}
            >
              Father
            </button>
            <button
              className={
                selectedOptionsHyperTension.includes("Mother")
                  ? classes.selected
                  : classes.notSelected
              }
              onClick={() => handleOptionClickHyperTension("Mother")}
            >
              Mother
            </button>
            <button
              className={
                selectedOptionsHyperTension.includes("Siblings")
                  ? classes.selected
                  : classes.notSelected
              }
              onClick={() => handleOptionClickHyperTension("Siblings")}
            >
              Siblings
            </button>
          </div>
        </div>
      </div>
      <div className={classes.formRow}>
        <div className={classes.formElement}>
          <div className={classes.inputLabel}>
            Diabetic?<span className={classes.requiredStar}>*</span>
          </div>
          <select
            id="diabetic"
            name="diabetic"
            className={classes.formInput}
            onChange={handleInputChange}
          >
            <option value="No">No</option>
            <option value="Yes">Yes</option>
          </select>
        </div>
        <div className={classes.formElement}>
          <div className={classes.inputLabel}>Family History of Diabetic?</div>
          <div className={classes.optionSec}>
            <button
              className={
                selectedOptionsDiabeteic.includes("Father")
                  ? classes.selected
                  : classes.notSelected
              }
              onClick={() => handleOptionClickDiabetic("Father")}
            >
              Father
            </button>
            <button
              className={
                selectedOptionsDiabeteic.includes("Mother")
                  ? classes.selected
                  : classes.notSelected
              }
              onClick={() => handleOptionClickDiabetic("Mother")}
            >
              Mother
            </button>
            <button
              className={
                selectedOptionsDiabeteic.includes("Siblings")
                  ? classes.selected
                  : classes.notSelected
              }
              onClick={() => handleOptionClickDiabetic("Siblings")}
            >
              Siblings
            </button>
          </div>
        </div>
      </div>
      <div className={classes.formRow}>
        <div className={classes.formElement}>
          <div className={classes.inputLabel}>Major Health Issue?</div>
          <select
            id="healthIssues"
            name="healthIssues"
            className={classes.formInput}
            onChange={handleInputChange}
          >
            <option value="None">None</option>
            <option value="Cancer">Cancer</option>
            <option value="Stroke">Stroke</option>
            <option value="Mental_Health_Disorder">
              Mental Health Disorder
            </option>
            <option value="Autoimmune_diseases">Autoimmune Diseases</option>
            <option value="Kidney_disease">Kidney Disease</option>
          </select>
        </div>
        <div className={classes.formElement}>
          <div className={classes.inputLabel}>Taking any medications for?</div>
          <div className={classes.optionSec}>
            <button
              className={
                selectedOptionsMedication.includes("Diabetes")
                  ? classes.selected
                  : classes.notSelected
              }
              style={{ fontSize: "0.8vw" }}
              onClick={() => handleOptionClickMedication("Diabetes")}
            >
              Diabetes
            </button>
            <button
              className={
                selectedOptionsMedication.includes("Thryroid")
                  ? classes.selected
                  : classes.notSelected
              }
              style={{ fontSize: "0.8vw" }}
              onClick={() => handleOptionClickMedication("Thryroid")}
            >
              Thryroid
            </button>
            <button
              className={
                selectedOptionsMedication.includes("BP")
                  ? classes.selected
                  : classes.notSelected
              }
              style={{ fontSize: "0.8vw" }}
              onClick={() => handleOptionClickMedication("BP")}
            >
              BP
            </button>
            <button
              className={
                selectedOptionsMedication.includes("Cholestrol")
                  ? classes.selected
                  : classes.notSelected
              }
              style={{ fontSize: "0.8vw" }}
              onClick={() => handleOptionClickMedication("Cholestrol")}
            >
              Cholestrol
            </button>
          </div>
        </div>
      </div>
      <div className={classes.formRow}>
        <div className={classes.submitSec}>
          <button
            className={
              isChecked ? classes.recordBtn : classes.recordBtnDisabled
            }
            onClick={(e) =>
              startRecording(
                e,
                selectedOptionsDiabeteic,
                selectedOptionsHyperTension,
                selectedOptionsMedication
              )
            }
            id="record-btn"
          >
            Record
          </button>
        </div>
      </div>
    </>
  );
};

export default Form2;
