import React, { useState, useEffect } from "react";
import classes from "./Selftest2.module.css";
import Navbar from "../Navbar/Navbar.js";
import { updateInput } from "./formUtil.js";
import WebcamRecorder from "./circ-webcam.js";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import Form2 from "./Form2.js";
import Form3 from "./Form3.js";

const Selftest2 = () => {
  const navigate = useNavigate();
  const [isRecording, setRecording] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [isModelLoading, setModelLoading] = useState(false);
  const [nextClicked, setNextClicked] = useState(false);
  const [isFormComplete, setIsFormComplete] = useState(false);
  const [symptomsSubmitted, setSymptomsSubmitted] = useState(false);
  const [symptomClicked, setSymptomClicked] = useState(false);
  const [userform, setuserform] = useState();
  const [doshavalues, setDoshavalues] = useState();
  const [doshabalances, setDoshabalances] = useState();
  const location = useLocation();

  const validateForm = (userform) => {
    // Regular expressions for validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const mobileRegex = /^\d{10}$/;
    const ageRegex = /^\d+$/;
    const weightRegex = /^\d+(\.\d+)?$/;
    const heightRegex = /^\d+(\.\d+)?$/;

    // Validate each field
    if (!userform.name.trim()) {
      alert("Please enter a name.");
      return false;
    }

    if (userform.email !== "" && !emailRegex.test(userform.email)) {
      alert("Please enter a valid email address.", userform.email);
      return false;
    }
    if (userform.email === "" && document.getElementById("email").required) {
      alert("Please enter a valid email address.", userform.email);
      return false;
    }

    if (!mobileRegex.test(userform.phoneNumber)) {
      alert("Please enter a valid 10-digit mobile number.");
      return false;
    }

    if (!ageRegex.test(userform.age)) {
      alert("Please enter a valid age.");
      return false;
    }

    if (!weightRegex.test(userform.weight)) {
      alert("Please enter a valid weight.");
      return false;
    }

    if (!heightRegex.test(userform.height)) {
      alert("Please enter a valid height.");
      return false;
    }

    // Validate gender
    if (userform.gender !== "Male" && userform.gender !== "Female") {
      alert("Please select a valid gender (Male/Female).");
      return false;
    }

    // Validate diabetic
    if (userform.diabetic !== "yes" && userform.diabetic !== "no") {
      alert("Please select a valid option for diabetic (yes/no).");
      return false;
    }

    return true; // Form is valid
  };

  const checkFormCompletion = () => {
    const name = document.getElementById("name").value.trim();
    const email = document.getElementById("email").value.trim();
    const phoneNumber = document.getElementById("phoneNumber").value.trim();
    const age = document.getElementById("age").value.trim();
    const weight = document.getElementById("weight").value.trim();
    const height = document.getElementById("height").value.trim();
    const gender = document.getElementById("gender").value.trim();
    const diabetic = document.getElementById("diabetic").value.trim();
    const activityFactor = document
      .getElementById("activityFactor")
      .value.trim();

    console.log(
      name,
      email,
      phoneNumber,
      age,
      weight,
      height,
      gender,
      diabetic,
      activityFactor
    );

    if (
      name &&
      phoneNumber &&
      age &&
      weight &&
      height &&
      gender &&
      diabetic &&
      activityFactor
    ) {
      setuserform({
        name: name,
        email: email,
        phoneNumber: phoneNumber,
        age: age,
        weight: weight,
        height: height,
        gender: gender,
        diabetic: diabetic,
        activityFactor: activityFactor,
      });

      setIsFormComplete(true);
    } else {
      setIsFormComplete(false);
    }
  };

  const handleNextClick = () => {
    if (userform && validateForm(userform)) {
      setNextClicked(true);
      console.log("userform", userform);
    }
  };

  const startRecording = (e) => {
    if (isRecording) return;
    if (!isChecked) {
      window.alert("Please agree to the privacy policy");
      return;
    }
    if (!symptomsSubmitted) {
      alert("Please submit the types of symptoms");
      return;
    }

    if (!userform) return;
    setModelLoading(performance.now());
    setRecording(true);
  };

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  useEffect(() => {
    const getCategory = async () => {
      if (!doshavalues) return;
      const vata = doshavalues["vata"];
      const pitta = doshavalues["pitta"];
      const kapha = doshavalues["kapha"];
      var category;
      console.log("vata", vata, "pitta", pitta, "kapha", kapha);
      await axios
        .get("https://iterve.aivot.ai/apollouser1/categorizeDosha", {
          params: {
            vata,
            pitta,
            kapha,
          },
        })
        .then(async (res) => {
          console.log("categorize dosha res", res.data);
          category = res.data;
        })
        .catch((err) => {
          console.log("error in dosha category", err);
        });

      setuserform((prevForm) => ({
        ...prevForm,
        vatavalue: doshavalues["vata"],
        pittavalue: doshavalues["pitta"],
        kaphavalue: doshavalues["kapha"],
        doshaCombination: category,
      }));
    };
    getCategory();
    // console.log("values changed", userform);
  }, [doshavalues]);

  useEffect(() => {
    doshabalances &&
      setuserform((prevForm) => ({
        ...prevForm,
        vatabalance: doshabalances["Vata"],
        pittabalance: doshabalances["Pitta"],
        kaphabalance: doshabalances["Kapha"],
      }));
    // console.log("balances changed", userform);
  }, [doshabalances]);

  useEffect(() => {
    console.log("updated", userform);
  }, [userform]);

  return (
    <div className={classes.container} id="content">
      <Navbar />
      <div className={classes.pageHeading}>Vitals measurement</div>
      <div className={classes.contentLayout}>
        <div className={classes.alertDiv}>
          Note: Before the video recording starts, please make sure the room is
          properly lit, especially your face. Also, make sure that no backlight
          is shining directly into the camera.
        </div>
        <div className={classes.mainContent}>
          {nextClicked ? (
            !symptomClicked ? (
              <div className={classes.detailsContainer1}>
                <Form2
                  setSymptomClicked={setSymptomClicked}
                  setDoshavalues={setDoshavalues}
                />
              </div>
            ) : (
              <div className={classes.detailsContainer1}>
                <Form3
                  setSymptomsSubmitted={setSymptomsSubmitted}
                  setDoshabalances={setDoshabalances}
                />
              </div>
            )
          ) : (
            <div className={classes.detailsContainer}>
              <div className={classes.formRow}>
                <div className={classes.formElement}>
                  <div className={classes.inputLabel}>
                    Name:<span className={classes.requiredStar}>*</span>
                  </div>
                  <input
                    required
                    autoComplete="off"
                    id="name"
                    className={classes.formInput}
                    placeholder="Enter your name"
                    onChange={checkFormCompletion}
                  />
                </div>
                <div className={classes.formElement}>
                  <div className={classes.inputLabel}>
                    Email ID:
                    {location.state !== "fromApolloMember" ? (
                      <span className={classes.requiredStar}>*</span>
                    ) : (
                      ""
                    )}
                  </div>
                  <input
                    autoComplete="off"
                    id="email"
                    className={classes.formInput}
                    placeholder="Enter your email"
                    required={location.state !== "fromApolloMember"}
                    onChange={checkFormCompletion}
                  />
                </div>
              </div>
              <div className={classes.formRow}>
                <div className={classes.formElement}>
                  <div className={classes.inputLabel}>
                    Mobile Number:
                    <span className={classes.requiredStar}>*</span>
                  </div>
                  <input
                    required
                    autoComplete="off"
                    id="phoneNumber"
                    type="number"
                    className={classes.formInput}
                    placeholder="Enter your mobile number"
                    onChange={checkFormCompletion}
                  />
                </div>
                <div className={classes.formElement}>
                  <div className={classes.inputLabel}>
                    Age:<span className={classes.requiredStar}>*</span>
                  </div>
                  <input
                    required
                    autoComplete="off"
                    id="age"
                    className={classes.formInput}
                    placeholder="Enter your age (in years)"
                    onChange={checkFormCompletion}
                  />
                </div>
              </div>
              <div className={classes.formRow}>
                <div className={classes.formElement}>
                  <div className={classes.inputLabel}>
                    Weight:<span className={classes.requiredStar}>*</span>
                  </div>
                  <input
                    required
                    autoComplete="off"
                    id="weight"
                    type="number"
                    className={classes.formInput}
                    placeholder="Enter your weight (in kilograms)"
                    step="0.01"
                    min="20"
                    max="200"
                    onBlur={(e) => {
                      const weight = parseFloat(e.target.value);
                      if (weight < 20 || weight > 200) {
                        alert("Please enter a weight between 20 and 200 kg.");
                        e.target.value = "";
                        e.target.focus();
                      }
                    }}
                    onChange={checkFormCompletion}
                  ></input>
                </div>
                <div className={classes.formElement}>
                  <div className={classes.inputLabel}>
                    Height:<span className={classes.requiredStar}>*</span>
                  </div>
                  <input
                    required
                    autoComplete="off"
                    id="height"
                    type="number"
                    className={classes.formInput}
                    placeholder="Enter your height (in meters)"
                    step="0.01"
                    min="1"
                    max="2.2"
                    onBlur={(e) => {
                      const height = parseFloat(e.target.value);
                      if (height < 1 || height > 2.2) {
                        alert(
                          "Please enter a height between 1 and 2.2 meters."
                        );
                        e.target.value = "";
                        e.target.focus();
                      }
                    }}
                    onChange={checkFormCompletion}
                  ></input>
                </div>
              </div>
              <div className={classes.formRow}>
                <div className={classes.formElement}>
                  <div className={classes.inputLabel}>
                    Gender:<span className={classes.requiredStar}>*</span>
                  </div>
                  <select
                    id="gender"
                    name="Gender"
                    className={classes.formInput}
                    onChange={checkFormCompletion}
                  >
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                  </select>
                </div>
                <div className={classes.formElement}>
                  <div className={classes.inputLabel}>
                    Diabetic ?<span className={classes.requiredStar}>*</span>
                  </div>
                  <select
                    id="diabetic"
                    name="Diabetic"
                    className={classes.formInput}
                    onChange={checkFormCompletion}
                  >
                    <option value="no">No</option>
                    <option value="yes">Yes</option>
                  </select>
                </div>
              </div>
              <div className={classes.formRow}>
                <div className={classes.formElement}>
                  <div className={classes.inputLabel}>
                    Activity factor:
                    <span className={classes.requiredStar}>*</span>
                  </div>
                  <select
                    id="activityFactor"
                    name="activity_factor"
                    className={classes.formInput}
                    onChange={checkFormCompletion}
                  >
                    <option value="1.2">Sedentary</option>
                    <option value="1.375">Lightly active</option>
                    <option value="1.55">Moderately active</option>
                    <option value="1.725">Very active</option>
                    <option value="1.9">Extra active</option>
                  </select>
                </div>
                <div className={classes.submitSec}>
                  <button
                    className={
                      isFormComplete ? classes.recordBtn : classes.recordBtn1
                    }
                    onClick={handleNextClick}
                    // disabled={!isFormComplete}
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          )}

          <div className={classes.image}>
            <WebcamRecorder
              startRecording={isRecording}
              setRecording={setRecording}
              setLoading={setModelLoading}
              isChecked={isChecked}
              userform={userform}
            />
            <div className={classes.buttondiv}>
              {window.innerWidth >= 768 ? (
                <button
                  onClick={() => {
                    setRecording(false);
                    navigate("/reports1");
                  }}
                  className={classes.signout}
                >
                  Back
                </button>
              ) : (
                <></>
              )}
              <button
                className={
                  isChecked && symptomsSubmitted
                    ? classes.signout
                    : classes.recordBtnDisabled1
                }
                onClick={startRecording}
                id="record-btn"
              >
                Record
              </button>
            </div>
          </div>
        </div>
        <div className={classes.tncContainer}>
          <div className={classes.disclaimer}>
            Your video and picture is not stored in our database; our system
            extracts only RGB and rPPG from camera processing and uses it to
            derive your health vitals.
          </div>
          <label className={classes.checkboxLabel}>
            <input
              type="checkbox"
              checked={isChecked}
              onChange={handleCheckboxChange}
              className={classes.checkbox}
            />
            I agree to share my data for research and training purposes.
          </label>
        </div>
        {window.innerWidth < 768 ? (
          <button
            onClick={() => {
              setRecording(false);
              navigate("/reports1");
            }}
            className={classes.back}
          >
            Back
          </button>
        ) : (
          <></>
        )}
      </div>
      {isModelLoading && (
        <div className={classes.loadingOverlay}>
          <div className={classes.loadingText}>Please wait...</div>
        </div>
      )}
    </div>
  );
};

export default Selftest2;
