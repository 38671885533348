import React, { useState, useRef, useEffect } from "react";
import classes from "./reportVitals.module.css";
import apolloLogo from "../Images/apolloLogo.png";
import aivotLogo from "../Images/aivotLogo 2.png";
import watermark from "../Images/watermark.png";
import HiimsLogo from "../Images/HIIMSLogo.png";
import { useLocation } from "react-router-dom";
import { PDFDownloadLink, View, pdf } from "@react-pdf/renderer";
import MyPDF from "./myPDF";
import { v4 as uuidv4 } from "uuid";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import wpimage from "../Images/whatsapp.png";
import mailimage from "../Images/mail.png";
import appleimage from "../Images/apple.png";
import androidimage from "../Images/android.png";
import webimage from "../Images/www.png";
import copyimage from "../Images/copy.png";
import copydone from "../Images/done.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import tickimage from "../Images/tick.png";
import cross from "../Images/remove.png";
import gamil from "../Images/gmail.png";
import yahoo from "../Images/yahoo.png";
import outlook from "../Images/outlook.png";
import userdata from "../typesofusers.json";

const ReportVitals = () => {
  const mypdfRef = useRef(null);
  const [isDownloaded, setDownloaded] = useState(null);
  const [blobUrl, setBlobUrl] = useState("");
  const [healthParameters, sethealthParameters] = useState([]);
  const [doshaParameters, setdoshaParameters] = useState([]);
  const [doshacombinations, setdoshacombinations] = useState("");
  const [formData, setFormData] = useState({});
  const [vitals, setvitals] = useState();
  const [childid, setchildid] = useState("");
  const [parentid, setparentid] = useState("");
  const [sharepopup, setsharepopup] = useState(false);
  const [whatsappopt, setwhatsappopt] = useState(false);
  const [mailopt, setmailopt] = useState(false);
  const [copycompleted, setcopycompleted] = useState(false);
  const [accountNumber, setaccountNumber] = useState("");
  const [enduserid, setenduserid] = useState("");
  const [mealrecommended, setmealrecommended] = useState({});
  console.log("vitals page rendered");

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    console.log(location.state);
    console.log(parentid, childid);

    if (location.state) {
      setvitals(location.state);
      console.log(location.state);
      setenduserid(location.state.id);
      console.log(localStorage.getItem("accno"));
      var accno = localStorage.getItem("accno");
      if (!accno) {
        accno = "";
      }
      setaccountNumber(accno);
      if (accno !== "" && accno !== "enduser") {
        console.log("accno exists", accno);
        var findid = "";
        console.log("before report vitals", accno);
        const dbcollection =
          userdata.form2user &&
          userdata.form2user.includes(localStorage.getItem("accno"))
            ? "apollouser1"
            : "apollouser";
        console.log("user id geting fetched from", dbcollection, "collection");
        axios
          .get(`https://iterve.aivot.ai/${dbcollection}/getuserid`, {
            params: { accno },
          })
          .then((res) => {
            findid = res.data;
            console.log(res.data);
            setparentid(res.data);
          })
          .catch((e) => {
            console.log(e);
          });
        setchildid(location.state._id);
      } else {
        setparentid("enduser");
        setchildid(location.state.id);
      }
      // fetch user Id as object id and set it
    } else {
      console.log("else");
      const objectId = location.pathname.split("/")[2].slice(4);
      const vitalsDataId = location.pathname.split("/")[3].slice(4);
      setparentid(objectId);
      setchildid(vitalsDataId);
      console.log(parentid, childid);
      if (!objectId || !vitalsDataId) navigate("/reports");
      const dbcollection = objectId === "enduser" ? "enduser" : "apollouser";
      fetch(
        `https://iterve.aivot.ai/${dbcollection}/fetchSingleVital?objectId=${objectId}&vitalDataId=${vitalsDataId}`,
        {
          method: "GET",
        }
      )
        .then((res) => res.json())
        .then((res) => {
          console.log(res);
          setvitals(objectId === "enduser" ? res : res.vitalsData);
          setaccountNumber(res.accountNumber ? res.accountNumber : "");
        });
    }
  }, [location.state]);

  useEffect(() => {
    console.log(vitals);

    if (vitals) {
      setdoshacombinations(vitals["doshaCombination"]);
      sethealthParameters([
        {
          vital: "Heart Rate",
          value: vitals["heart_rate"],
          unit: "Beats per min",
          referenceRange: "60 - 100",
        },
        {
          vital: "Oxygen Saturation %",
          value: vitals["oxygen_saturation"],
          unit: "%",
          referenceRange: "95 – 100",
        },
        // {
        //   vital: "Breathing Rate",
        //   value: vitals["breathing_rate"],
        //   unit: "BPM",
        //   referenceRange: "12 – 20",
        // },
        // {
        //   vital: "Pulse Rate Quotient",
        //   value: vitals["pulse_rate_quotient"],
        //   unit: "Beats per min",
        //   referenceRange: "4",
        // },
        {
          vital: "BMI",
          value: vitals["bmi"],
          unit: "kg/m^2",
          referenceRange: "18.5 – 24.9",
        },
        {
          vital: "Body Water %",
          value: vitals["body_water"],
          unit: "%",
          referenceRange: vitals["gender"] === "Male" ? "43 - 73" : "41 - 60",
        },
        {
          vital: "Body Fat",
          value: vitals["body_fat"],
          unit: "%",
          referenceRange: vitals["gender"] === "Male" ? "8 - 21" : "14 - 28",
        },
        {
          vital: "Visceral Fat",
          value: vitals["visceral_fat"],
          unit: "%",
          referenceRange: "1 - 12",
        },
        {
          vital: "Subcutaneous Fat %",
          value: vitals["subcutaneous_fat"],
          unit: "%",
          referenceRange: vitals["gender"] === "Male" ? "8 - 25" : "20 - 35",
        },
        {
          vital: "Cardiac Output",
          value: vitals["cardiac_output"],
          unit: "L/min",
          referenceRange: "4 – 8",
        },
        {
          vital: "Cardiac Index",
          value: vitals["cardiac_index"],
          unit: "L/min/m^2",
          referenceRange: "2.6 – 4.2",
        },
        {
          vital: "Stroke Volume",
          value: vitals["stroke_volume"],
          unit: "milli litre",
          referenceRange: "48.2 - 98.5",
        },
        {
          vital: "RMSSD",
          value: vitals["rmssd"],
          unit: "milli sec",
          referenceRange: "19 – 107",
        },
        {
          vital: "SDNN",
          value: vitals["sdnn"],
          unit: "milli sec",
          referenceRange: ">55",
        },
        {
          vital: "SD1",
          value: vitals["sd1"],
          unit: "milli sec",
          referenceRange: vitals["gender"] === "Male" ? "40.5 - 71" : "29 - 65",
        },
        {
          vital: "SD2",
          value: vitals["sd2"],
          unit: "milli sec",
          referenceRange: "35 - 107",
        },
        {
          vital: "IBI",
          value: vitals["ibi"],
          unit: "milli sec",
          referenceRange: "600 – 900",
        },
        {
          vital: "Stress Index*",
          value: vitals["stress_index"],
          unit: "%",
          referenceRange: "20 – 80",
        },
        {
          vital: "SBP",
          value: vitals["sbp"],
          unit: "mmHg",
          referenceRange: "100 – 130",
        },
        {
          vital: "DBP",
          value: vitals["dbp"],
          unit: "mmHg",
          referenceRange: "65 - 90",
        },

        {
          vital: "Mean Arterial Pressure",
          value: vitals["mean_atrial_pressure"],
          unit: "mmHg",
          referenceRange: "70 - 100",
        },
        {
          vital: "Pulse Pressure",
          value: vitals["pulse_pressure"],
          unit: "mmHg",
          referenceRange: "44 – 60",
        },
        {
          vital: "LDL*",
          value: vitals["ldl"],
          unit: "mg/dl",
          referenceRange: "50 - 130",
        },
        {
          vital: "HDL*",
          value: vitals["hdl"],
          unit: "mg/dl",
          referenceRange: "40 - 75",
        },
        {
          vital: "Triglycerides*",
          value: vitals["triglycerides"],
          unit: "mg/dl",
          referenceRange: "60 - 150",
        },
        {
          vital: "Total Cholesterol*",
          value: vitals["total_cholestrol"],
          unit: "mg/dl",
          referenceRange: "140 - 200",
        },
        {
          vital: "VLDL*",
          value: vitals["vldl"],
          unit: "mg/dl",
          referenceRange: "5 - 40",
        },
        {
          vital: "RBC Count*",
          value: vitals["rbc_count"],
          unit: "million cell/mcl",
          referenceRange:
            vitals["gender"] === "Male" ? "4.7 - 6.1" : "4.2 - 5.4",
        },
        {
          vital: "Mean Corpuscular Value*",
          value: vitals["mean_corpuscular_volume"],
          unit: "fl",
          referenceRange: "80 – 100",
        },
        {
          vital: "Mean Corpuscular Haemoglobin*",
          value: vitals["mean_corpuscular_hemoglobin"],
          unit: "pg/cells",
          referenceRange: "27 - 31",
        },
        // {
        //   vital: "T3 (Triiodothyronine)",
        //   value: "",
        //   unit: "ng/dl",
        //   referenceRange: "80-200",
        // },
        // {
        //   vital: "T4 (Thyroxine)",
        //   value: "",
        //   unit: "μg/dL",
        //   referenceRange: "4.8 – 12.7",
        // },
        // {
        //   vital: "TSH (Thyroid Stimulating Hormone)",
        //   value: "",
        //   unit: "μIU/mL",
        //   referenceRange: "0.54 – 5.30",
        // },
        // {
        //   vital: "Blood Volume",
        //   value: "",
        //   unit: "mL",
        //   referenceRange: "5000",
        // },
        {
          vital: "Hemoglobin",
          value: vitals["hemoglobin"],
          unit: "g/dl",
          referenceRange:
            vitals["gender"] === "Male" ? "13 - 17" : "11.6 - 15.5",
        },
        {
          vital: "Hematocrit",
          value: vitals["hematocrit"],
          unit: "%",
          referenceRange: vitals["gender"] === "Male" ? "40 - 54" : "36 - 48",
        },
        {
          vital: "Random Blood Sugar",
          value: vitals["random_blood_sugar"],
          unit: "mg/dl",
          referenceRange: "70 – 140",
        },
        {
          vital: "HbA1c",
          value: vitals["hba1c"],
          unit: "%",
          referenceRange: "4 - 5.6",
        },
        {
          vital: "Mean Plasma Glucose",
          value: vitals["mean_plasma_glucose"],
          unit: "mg/dl",
          referenceRange: "72 - 100",
        },
        {
          vital: "Allowable Blood Loss",
          value: vitals["allowable_blood_loss"],
          unit: "ml/kg",
          referenceRange: vitals["gender"] === "Male" ? "75" : "65",
        },
      ]);

      setdoshaParameters([
        {
          vital: "Vata",
          percentage: Math.round(parseFloat(vitals["vata"])),
          balance: vitals["vatabalance"],
        },
        {
          vital: "Pitta",
          percentage: Math.round(parseFloat(vitals["pitta"])),
          balance: vitals["pittabalance"],
        },
        {
          vital: "Kapha",
          percentage: Math.round(parseFloat(vitals["kapha"])),
          balance: vitals["kaphabalance"],
        },
      ]);

      console.log(vitals);
      setFormData({
        name: vitals["name"],
        phoneNumber: vitals["phoneNumber"],
        email: vitals["email"],
        subjectid: vitals["subjectid"],
        gender: vitals["gender"],
        age: vitals["age"],
        height: vitals["height"],
        weight: vitals["weight"],
      });
    }
  }, [vitals]);

  const dietRecommendations = {
    vatapitta: {
      Eat: {
        "Rasa(taste)": ["sweet", "bitter"],
        "Cereals(grains)": ["brown rice", "wheat"],
        "Pulses(legumes)": ["mung beans", "black & red lentils"],
        Vegetables: [
          "cooked vegetables",
          "asparagus",
          "beets",
          "carrots",
          "cucumber",
          "green beans",
          "okra (cooked)",
          "onion (cooked)",
          "potatoes (sweet)",
          "zucchini",
        ],
        Fruits: [
          "sweet fruits",
          "avocado",
          "bananas",
          "coconut",
          "figs (fresh)",
          "custard apple",
          "chikoo (sapodilla)",
          "lemon",
          "mango",
          "melons (sweet)",
          "papaya",
        ],
        Spices: [
          "coriander",
          "curry leaves",
          "fenugreek",
          "turmeric",
          "mustard",
          "cumin",
          "carom",
          "ginger",
          "asafetida",
          "cardamom",
        ],
        "Non-Veg": ["chicken", "seafood", "white meats", "meat of goat"],
        "Milk products": ["all dairy products"],
        "Oil products": ["oilve oil", "sunflower oil", "faxseed oil"],
        "Nuts/seeds": [
          "walnuts",
          "pistachio",
          "sunflower seeds",
          "pumpkin seeds",
        ],
      },
      Occasionally: {
        "Rasa(taste)": ["sour", "salty"],
        "Cereals(grains)": [
          "oats(cooked)",
          "rice(basmati)",
          "rice(white)",
          "wheat",
          "millate",
        ],
        "Pulses(legumes)": [],
        Vegetables: [
          "sweet & bitter vegetables",
          "cabbage",
          "cauliflower",
          "celery",
          "leafy greens",
          "Salads",
          "Ladyfinger",
          "peas",
          "parsley",
          "peppers (green)",
          "potatoes",
          "brussels sprouts (cooked)",
        ],
        Fruits: [
          "orange",
          "mosambi(sweet lemon)",
          "guava",
          "sweet pineapple",
          "melons",
          "grapes",
          "apricots",
          "berries",
          "cherries",
        ],
        Spices: ["fennel", "fresh ginger", "garlic", "all masalas"],
        "Non-Veg": ["river fish", "meat of goat", "shirmp", "poultry", "egg"],
        "Milk products": [],
        "Oil products": ["sesame oil"],
        "Nuts/seeds": ["soaked nuts"],
      },

      Avoid: {
        "Rasa(taste)": ["astringent", "pungent"],
        "Cereals(grains)": ["barley", "buckwheat", "corn", "oats(dry)", "rye"],
        "Pulses(legumes)": ["black beans", "kidney beans"],
        Vegetables: [
          "raw vegetables",
          "broccoli",
          "brussels sprouts",
          "pungent vegetables",
          "eggplant",
          "onions",
          "spinach",
          "tomatoes",
          "pepper(hot)",
        ],
        Fruits: [
          "dried fruits",
          "apples",
          "cranberries",
          "pears",
          "sour fruits",
          "berries",
          "cherries",
          "dried fruits",
          "apples",
          "cranberries",
          "pears",
          "persimmon",
          "pomegranate",
          "watermelon",
        ],
        Spices: ["Dry ginger", "chilly", "mustrad", "cinnamom", "tamarind"],
        "Non-Veg": [
          "Red meat",
          "lamb",
          "vension",
          "dark meats",
          "pork",
          "sea food ",
          "shellfish",
          "tuna",
        ],
        "Milk products": ["processed cheesse"],
        "Oil products": ["vegetable oil", "saffloer oil", "musterd oil"],
        "Nuts/seeds": ["dry nuts"],
      },
    },
    vatakapha: {
      Eat: {
        "Rasa(taste)": ["Sweet", "Sour", "salty"],
        "Cereals(grains)": ["oats(cooked)", "rice", "wheat"],
        "Pulses(legumes)": ["mung beans", "mung dal", "chickpeas"],
        Vegetables: [
          "cooked vegetables",
          "asparagus",
          "carrots",
          "green beans",
          "okra (cooked)",
          "onion (cooked)",
          "potatoes (sweet)",
          "radishes",
          "zucchini",
        ],
        Fruits: [
          "apricots",
          "avocado",
          "bananas",
          "berries",
          "cherries",
          "coconut",
          "grapes",
          "lemon",
          "mango",
          "melons(sweet)",
          "oranges",
          "papaya",
          "peaches",
          "pineapple(sweet)",
          "plums",
        ],
        Spices: [
          "curry leaves",
          "fenugreek",
          "turmeric",
          "mustard",
          "cumin",
          "carom",
          "asafetida",
          "cardamom",
        ],
        "Non-Veg": [
          "seafood",
          "turkey chicken",
          "white meats",
          "eggs",
          "shirmp",
        ],
        "Milk products": ["ghee", "butter", "butter milk"],
        "Oil products": [
          "ground nut oil",
          "sesame oil",
          "mustared oil",
          "olive oil",
        ],
        "Nuts/seeds": [
          "soaked(skinless) almonds",
          "brazil nuts",
          "cashew",
          "coconut",
          "macadamia",
          "pecan",
          "pine",
          "hazlenut",
          "walnuts",
          "pistachio",
          "sesame(tahini)",
          "flax",
          "sunflower",
          "pumpkin",
          "chia",
          "flax seed",
        ],
      },
      Occasionally: {
        "Rasa(taste)": ["Bitter", "pungent", "astringent(weekly once)"],
        "Cereals(grains)": [
          "barley",
          "corn",
          "millet",
          "oats(dry)",
          "rice (small amount – basmati",
        ],
        "Pulses(legumes)": [],
        Vegetables: [
          "pungent & bitter vegetables",
          "beets",
          "sprouts",
          "cauliflower",
          "celery",
          "leafy greens",
          "parsley",
          "peas",
          "potatoes (white)",
          "spinach",
        ],
        Fruits: [
          "Apples",
          "peaches",
          "pears",
          "persimmon",
          "pomegranate",
          "prunes",
          "raisins",
          "sweet fruits",
        ],
        Spices: [
          "Black pepper",
          "chilly",
          "ginger",
          "fennelseeds",
          "mint",
          "corom",
          "gralic",
          "coriander",
          "cinnamon",
        ],
        "Non-Veg": ["fresh water fish", "chicken", "poultry"],
        "Milk products": ["goat milk", "curd", "home made cheese"],
        "Oil products": ["almond", "corn", "sunflower (all in moderation)"],
        "Nuts/seeds": ["popcorn", "pumpkin seeds", "sunflower seeds"],
      },

      Avoid: {
        "Rasa(taste)": [],
        "Cereals(grains)": ["buckwheat", "rye", "rice (brown)"],
        "Pulses(legumes)": ["black beans", "kidney beans"],
        Vegetables: [
          "sweet & juicy vegetables",
          "cucumber",
          "tomatoes",
          "raw vegetables",
          "broccoli",
          "brussels sprouts",
          "cabbage",
          "eggplant",
          "lettuce",
          "mushrooms",
        ],
        Fruits: [
          "dried fruits",
          "cranberries",
          "pears",
          "watermelon",
          "sour fruits",
          "banana",
          "coconut",
        ],
        Spices: ["excess salt"],
        "Non-Veg": [
          "lamb",
          "rabbit",
          "pork",
          "vension",
          "Mutton",
          "fresh water fish",
          "red meat",
          "seafood",
          "salmon",
        ],
        "Milk products": ["curd", "all other dairy products"],
        "Oil products": ["all other oils"],
        "Nuts/seeds": [
          "dry rosted nuts",
          "peanuts",
          "psyllium",
          "chia seeds",
          "sesame seeds",
          "salted nuts",
        ],
      },
    },
    pittavata: {
      Eat: {
        "Rasa(taste)": ["Sweet", "bitter"],
        "Cereals(grains)": [
          "barley",
          "oats (cooked)",
          "rice(basmati)",
          "rice (white)",
          "wheat",
          "millet",
        ],
        "Pulses(legumes)": ["all legumes except lentils"],
        Vegetables: [
          "sweet & bitter vegetables",
          "asparagus",
          "cabbage",
          "cucumber",
          "cauliflower",
          "celery",
          "green beans",
          "leafy greens",
          "Salads",
          "Ladyfinger",
          "peppers (green)",
          "potatoes",
          "sprouts",
        ],
        Fruits: [
          "avocada",
          "orange(sweet)",
          "mosambi( sweet lemon)",
          "guava",
          "sweet pineapple(sweet)",
          "melons",
        ],
        Spices: [
          "Coriander",
          "cardamom",
          "fennel",
          "turmeric",
          "fresh ginger",
          "cumin",
        ],
        "Non-Veg": ["river fish", "poultery", "chicken"],
        "Milk products": [
          "butter (unsalted)",
          "cottage cheese",
          "ghee",
          "milk",
        ],
        "Oil products": [
          "coconut",
          "olive",
          "sunflower",
          "soy oil",
          "ground nut oil",
        ],
        "Nuts/seeds": [
          "almonds (soaked and peeled)",
          "coconut",
          "flaxseed",
          "popcorn",
          "pumpkin seeds",
          "sunflower seeds",
          "chai",
          "almond butter",
          "cashew",
        ],
      },
      Occasionally: {
        "Rasa(taste)": ["Sour", "salty", "astringent"],
        "Cereals(grains)": [],
        "Pulses(legumes)": ["mung beans", "black & red lentils"],
        Vegetables: [
          "cooked vegetables",
          "okra (cooked)",
          "onion (cooked)",
          "potatoes (sweet)",
          "zucchini",
        ],
        Fruits: [
          "sweet fruits",
          "apricots",
          "bananas",
          "berries",
          "cherries",
          "coconut",
          "figs (fresh)",
          "grapes",
          "mango",
          "peaches",
          "plums",
          "pomegranate",
          "apples",
        ],
        Spices: [
          "curryleaves",
          "fenugreek",
          "turmeric",
          "mustard",
          "carom",
          "gralic",
          "asafetida",
        ],
        "Non-Veg": [
          "seafood",
          "turkey chicken",
          "white meats",
          "eggs",
          "shrimp",
          "meat of goat",
        ],
        "Milk products": ["curd"],
        "Oil products": ["sesame oil"],
        "Nuts/seeds": [
          "brazil nuts",
          "macadamia",
          "pecan",
          "pine",
          "hazlenut",
          "walnuts",
          "pistachio",
          "sesame(tahini)",
          "flax",
        ],
      },

      Avoid: {
        "Rasa(taste)": ["pungent"],
        "Cereals(grains)": [
          "buckwheat",
          "corn",
          "oats (dry)",
          "rice (brown)",
          "corn",
          "rye",
        ],
        "Pulses(legumes)": ["lentils"],
        Vegetables: [
          "pungent vegetables",
          "beets",
          "carrots",
          "eggplant",
          "radishes",
          "spinach",
          "raw vegetables",
          "broccoli",
          "brussels sprouts",
          "celery",
          "eggplant",
          "leafy greens",
          "mushrooms",
          "onions (raw)",
          "parsley",
          "peas",
          "potatoes (white)",
          "sprouts",
          "tomatoes",
        ],
        Fruits: [
          "dried fruits",
          "cranberries",
          "pears",
          "persimmon",
          "watermelon",
          "sour fruits",
          "grapefruit",
          "lemons",
          "persimmon",
          "plums (sour)",
          "oranges (sour)",
          "pineapples (sour)",
          "papaya",
        ],
        Spices: [
          "Dry ginger",
          "chilly",
          "mustrad",
          "garlic",
          "cinnamom",
          "tamarind",
        ],
        "Non-Veg": [
          "dark meats",
          "pork",
          "shellfish",
          "tuna",
          "Red meat",
          "lamb",
        ],
        "Milk products": [
          "buttermilk",
          "cheese",
          "sour cream",
          "yogurt",
          "all dairy in moderation",
        ],
        "Oil products": ["almond", "corn", "safflower", "mustard oil"],
        "Nuts/seeds": [
          " nuts",
          "pecans",
          "penuts",
          "macadamia",
          "dry rosted nuts",
          "hemp",
        ],
      },
    },
    pittakapha: {
      Eat: {
        "Rasa(taste)": ["bitter", "astringent"],
        "Cereals(grains)": [
          "barley",
          "oats (cooked)",
          "rice (basmati)",
          "rice (white)",
          "wheat",
        ],
        "Pulses(legumes)": ["all legumes "],
        Vegetables: [
          "asparagus",
          "cabbage",
          "cucumber",
          "cauliflower",
          "celery",
          "green beans",
          "leafy greens",
          "Salads",
          "Ladyfinger",
          "peas",
          "parsley",
          "peppers (green)",
          "potatoes",
          "sprouts",
        ],
        Fruits: [
          "Apple",
          "avocada",
          "orange",
          "mosambi (lemon sweet)",
          "guava",
          "sweet pineapple",
          "melons",
        ],
        Spices: [
          "Cariander",
          "cardamom",
          "fennel",
          "turmeric",
          "fresh ginger",
          "cumin",
        ],
        "Non-Veg": ["river fish", "poultery", "egg"],
        "Milk products": ["ghee", "milk"],
        "Oil products": [
          "coconut oil",
          "olive oil",
          "ground nut oil",
          "soy oil",
        ],
        "Nuts/seeds": [
          "almonds (soaked and peeled)",
          "coconut",
          "flaxseed",
          "psyllium",
          "popcorn",
          "pumpkin seeds",
          "sunflower seeds",
        ],
      },
      Occasionally: {
        "Rasa(taste)": ["pungent", "sweet"],
        "Cereals(grains)": ["corn, millet", "oats (dry)"],
        "Pulses(legumes)": [
          "kidney beans",
          "soy beans",
          "black lentils",
          "mung beans",
          "lentils",
        ],
        Vegetables: ["pungent & bitter vegetables", "spinach"],
        Fruits: [
          "apricots",
          "berries",
          "cherries",
          "cranberries",
          "figs (dry)",
          "mango",
          "peaches",
          "pears",
          "persimmon",
          "pomegranate",
          "prunes",
          "raisins",
        ],
        Spices: [
          "Black pepper",
          "chilly (red)",
          "carom seed",
          "fennel",
          "mint",
          "corom seed",
          "asafetida",
          "small amount of cinnamom",
        ],
        "Non-Veg": [
          "Lean fish",
          "chicken",
          "white meat",
          "fresh water fish",
          "sirmp",
        ],
        "Milk products": ["goat milk", "butter", "cottage cheese"],
        "Oil products": ["almond", "corn", "sunflower (all in moderation)"],
        "Nuts/seeds": [],
      },

      Avoid: {
        "Rasa(taste)": ["Sour", "salty"],
        "Cereals(grains)": ["buckwheat", "rice (brown)", "rice (brown)"],
        "Pulses(legumes)": [],
        Vegetables: [
          "beets",
          "carrots",
          "eggplant",
          "onions",
          "peppers (hot)",
          "radishes",
          "spinach",
          "tomatoes",
          "sweet & juicy vegetables",
          "cucumber",
          "potatoes (sweet)",
        ],
        Fruits: [
          "sour fruits",
          "apricots",
          "bananas",
          "grapefruit",
          "lemons",
          "persimmon",
          "plums (sour)",
          "papaya",
          "peaches",
          "pineapples (sour)",
          "sweet & sour fruits",
          "banana",
          "coconut",
          "figs (fresh)",
          "melons",
          "oranges",
        ],
        Spices: ["Dry ginger", "mustrad", "garlic", "tamarind", "salt"],
        "Non-Veg": [
          "red meat",
          "dark meats",
          "pork",
          "sea food",
          "shellfish",
          "tuna",
          "Mutton",
          "fresh water fish",
          "turkey  chiken",
          "seafood",
          "salmon",
        ],
        "Milk products": ["buttermilk", "cheese", "sour cream", "yogurt"],
        "Oil products": ["sesame oil"],
        "Nuts/seeds": [
          "tahini/sesame seeds",
          "most nuts",
          "pecans",
          "penuts",
          "macadamia",
          "cashews",
          "walnuts",
          "chia seeds",
          "sesame seeds",
          "nuts",
        ],
      },
    },
    kaphavata: {
      Eat: {
        "Rasa(taste)": ["Bitter", "astringent"],
        "Cereals(grains)": [
          "barley",
          "corn",
          "millet",
          "oats (cooked)",
          "ric (small amount basmati)",
        ],
        "Pulses(legumes)": ["all legumes except as listed"],
        Vegetables: [
          "pungent & bitter vegetables",
          "asparagus",
          "beets",
          "sprouts",
          "carrots",
          "celery",
          "leafy greens",
          "Salads",
          "Ladyfinger",
          "parsley",
          "peas",
          "peppers",
        ],
        Fruits: [
          "Apples",
          "apricots",
          "berries",
          "cherries",
          "cranberries",
          "figs (dry)",
          "peaches",
          "pears",
          "persimmon",
          "pomegranate",
          "prunes",
          "raisins",
        ],
        Spices: [
          "Black pepper",
          "chilly",
          "ginger",
          "turmeric",
          "cumin",
          "carom seed",
          "fennel",
          "mint",
          "corom seed",
          "coriander",
          "cinnamon",
          "asafetida",
          "curry leaves",
        ],
        "Non-Veg": [
          "Lean fish",
          "chicken",
          "white meat",
          "fresh water fish",
          "poultry",
        ],
        "Milk products": ["ghee", "goat milk", "milk"],
        "Oil products": ["sesame oil", "ground nut oil", "mustared oil"],
        "Nuts/seeds": [
          "soaked (skinless) almonds",
          " coconut",
          "falxseed",
          "popcorn",
          "pumpkin seeds",
          "sunflower seeds",
        ],
      },
      Occasionally: {
        "Rasa(taste)": ["Sweet", "Sour", "salty"],
        "Cereals(grains)": ["rice", "wheat"],
        "Pulses(legumes)": ["mung beans", "black & red lentils"],
        Vegetables: [
          "cooked vegetables",
          "cucumber",
          "green beans",
          "okra (cooked)",
          "onion (cooked)",
          "potatoes (sweet)",
          "radishes",
          "zucchini",
        ],
        Fruits: [
          "sweet fruits",
          "apricots",
          "avocado",
          "bananas",
          "coconut",
          "figs (fresh)",
          "grapes",
          "lemon",
          "mango",
          "melons (sweet)",
          "oranges",
          "papaya",
          "plums",
        ],
        Spices: [
          "fenugreek",
          "mustard",
          "cumin",
          "carom",
          "gralic",
          "asafetida",
          "cinnamon",
          "cardamom",
        ],
        "Non-Veg": [
          "seafood",
          "turkey chicken",
          "white meats",
          "eggs",
          "shrimp",
          "goat meat",
        ],
        "Milk products": ["curd", "butter milk", "chees"],
        "Oil products": ["coconut oil"],
        "Nuts/seeds": [
          "brazil nuts",
          "cashew",
          "coconut",
          "macadamia",
          "pecan",
          "pine",
          "hazlenut",
          "walnuts",
          "pistachio",
          "sesame(tahini)",
          "flax",
          "hemp",
        ],
      },

      Avoid: {
        "Rasa(taste)": ["pungent", "sweet"],
        "Cereals(grains)": [
          "oats (dry)",
          "rice (brown)",
          "rice (white)",
          "wheat",
          "buckwheat",
          "rye",
        ],
        "Pulses(legumes)": ["kidney beans", "soy beans", "black lentils"],
        Vegetables: [
          "raw vegetables",
          "broccoli",
          "brussels sprouts",
          "cabbage",
          "cauliflower",
          "eggplant",
          "lettuc",
          "mushrooms",
          "onions (raw)",
          "parsley",
          "potatoes (white)",
          "spinach",
          "sprouts",
          "tomatoes",
          "sweet & juicy vegetables",
        ],
        Fruits: [
          "sour fruits",
          "coconut",
          "figs (fresh)",
          "pineapple",
          "plums",
          "dried fruits",
          "pears",
          "watermelon",
        ],
        Spices: ["salt (moderate)"],
        "Non-Veg": [
          "lamb",
          "rabbit",
          "pork",
          "vension",
          "Mutton",
          "red meat",
          "seafood",
          "salmon",
        ],
        "Milk products": ["curd"],
        "Oil products": [],
        "Nuts/seeds": [
          "dry rosted nuts",
          "peanuts",
          "psyllium",
          "chia seeds",
          "nuts",
        ],
      },
    },
    kaphapitta: {
      Eat: {
        "Rasa(taste)": ["Bitter", "pungent", "astringent"],
        "Cereals(grains)": [
          "barley",
          "corn",
          "millet",
          "oats (dry)",
          "rice (small amount – basmati)",
        ],
        "Pulses(legumes)": ["all legumes"],
        Vegetables: [
          "pungent & bitter vegetables",
          "asparagus",
          "beets",
          "sprouts",
          "cabbage",
          "carrots",
          "cauliflower",
          "celery",
          "leafy greens",
          "Salads",
          "Ladyfinger",
          "parsley",
          "peas",
        ],
        Fruits: [
          "Apples",
          "apricots",
          "berries",
          "cherries",
          "cranberries",
          "figs (dry)",
          "peaches",
          "pears",
          "persimmon",
          "prunes",
          "raisins",
        ],
        Spices: [
          "Black pepper",
          "green chilli",
          "turmic",
          "cumin",
          "carom seed",
          "fennel",
          "mint",
          "corom seed",
          "coriander",
          "asafetida",
        ],
        "Non-Veg": [
          "Lean fish",
          "chicken",
          "white meat",
          "fresh water fish",
          "shrimp",
        ],
        "Milk products": ["ghee", "goat milk"],
        "Oil products": ["corn", "sunflower (all in moderation)"],
        "Nuts/seeds": [
          "coconut",
          "flaxseed",
          "popcorn",
          "pumpkin seeds",
          "sunflower seeds",
        ],
      },
      Occasionally: {
        "Rasa(taste)": ["Sweet"],
        "Cereals(grains)": ["oats (cooked)", "wheat"],
        "Pulses(legumes)": ["kidney beans "],
        Vegetables: [
          "eggplant",
          "onions",
          "peppers (hot)",
          "radishes",
          "spinach",
          "tomatoes",
        ],
        Fruits: [
          "orange(sweet)",
          "lemon (sweet)",
          "guava",
          "papaya",
          "pineapple (sweet)",
          "pomegranate",
          "melons",
        ],
        Spices: ["fresh ginger", "cinnamon", "dry ginger"],
        "Non-Veg": ["poultry", "egg white"],
        "Milk products": ["butter (unsalted)", "cottage cheese", "milk"],
        "Oil products": ["coconut", "olive", "soy", "mustard oil"],
        "Nuts/seeds": [
          "almonds (soaked and peeled)",
          "psyllium",
          "popcorn",
          "chai seeds",
        ],
      },

      Avoid: {
        "Rasa(taste)": ["sour", "salty"],
        "Cereals(grains)": [
          "rice (brown)",
          "rice (white)",
          "wheat",
          "buckwheat",
        ],
        "Pulses(legumes)": ["horse gram"],
        Vegetables: [
          "sweet & juicy vegetables",
          "cucumber",
          "potatoes (sweet)",
          "potato (white)",
        ],
        Fruits: [
          "sweet & sour fruits",
          "avocado",
          "bananas",
          "coconut",
          "figs (fresh)",
          "grapes",
          "lemons",
          "oranges",
          "papaya",
          "plums",
          "apricots",
          "lemons",
          "persimmon",
          "plums (sour)",
          "oranges (sour)",
          "peaches",
          "pineapples (sour)",
          "custard apple",
          "sapota",
        ],
        Spices: [
          "salt",
          "mustard",
          "garlic",
          "ginger",
          "tamarind",
          "red chilli",
        ],
        "Non-Veg": [
          "Mutton",
          "red meat",
          "turkey  chicken",
          "salmon",
          "dark meats",
          "pork",
          "sea food ",
          "shellfish",
          "tuna",
          "meat of goat",
        ],
        "Milk products": ["butter milk", "cheese", "sour cream", "yogurt"],
        "Oil products": ["almond", "corn", "safflower", "sesame"],
        "Nuts/seeds": [
          "tahini/sesame seeds",
          "most nuts",
          "pecans",
          "peanuts",
          "macadamia",
          "cashews",
          "walnuts",
        ],
      },
    },
    vatapittakapha: {
      Eat: {
        "Rasa(taste)": [],
        "Cereals(grains)": ["Barley", "Oats"],
        "Pulses(legumes)": ["Dal", "Gram"],
        Vegetables: ["Cauliflower"],
        Fruits: ["Apple", "Pomegranate"],
        Spices: ["Basil", "Pepper"],
        "Non-Veg": ["Chicken", "Fish"],
        "Milk Products": ["Milk", "Ghee"],
        "Oil products": ["Olive Oil"],
        "Nuts/seeds": ["Almonds", "Raisins"],
      },

      Occasionally: {
        "Rasa(taste)": [],
        "Cereals(grains)": [],
        "Pulses(legumes)": [],
        Vegetables: [],
        Fruits: [],
        Spices: [],
        "Non-Veg": [],
        "Milk products": [],
        "Oil products": [],
        "Nuts/seeds": [],
      },
      Avoid: {
        "Rasa(taste)": [],
        "Cereals(grains)": ["Corn", "Muesli"],
        "Pulses(legumes)": ["Black Dal", "Beans"],
        Vegetables: ["Okra", "Onion"],
        Fruits: ["Banana", "Mango"],
        Spices: ["Mint"],
        "Non-Veg": ["Pork", "Beef"],
        "Milk Products": ["Cheese", "Yogurt"],
        "Oil products": ["Mustard Oil"],
        "Nuts/seeds": ["Cashews", "Pistachios"],
      },
    },
  };

  useEffect(() => {
    // setmealrecommended(dietRecommendations[doshacombinations.toLowerCase()]);
    axios
      .get("https://iterve.aivot.ai/apollouser1/dietRecommendation", {
        params: { category: doshacombinations },
      })
      .then((res) => {
        console.log(res);
        setmealrecommended(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [doshacombinations]);

  useEffect(() => {
    console.log(doshacombinations);
    console.log(mealrecommended);
  }, [mealrecommended]);

  const tableHead = ["Name", "Subject ID", "Gender", "Age", "Height", "Weight"];

  useEffect(() => {
    if (vitals) console.log("vitals", Object.keys(vitals), vitals);
  }, [vitals]);

  const [recipientEmail, setRecipientEmail] = useState("aniroycr537@gmail.com");
  const [file, setFile] = useState(null);

  const handleMailButtonClick = async (prov) => {
    try {
      // Generate PDF blob
      const blob = await pdf(
        <MyPDF
          healthParameters={healthParameters}
          formData={formData}
          accno={accountNumber}
        />
      ).toBlob();

      // Create FormData object to send the blob
      const emailform = new FormData();
      emailform.append("file", blob, "mypdf.pdf");

      // Additional data (recipient email)
      emailform.append("recipientEmail", "aniruddha@aivot.ai");

      // console.log(parentid,childid);

      if (prov === "gmail") {
        var link = `https://mail.google.com/mail/u/0/?fs=1&to&su=check+out+the+health+vitals&body=https://iterve.aivot.ai/vitals/pid%3D${parentid}/cid%3D${childid}&tf=cm`;
      } else if (prov === "yahoo") {
        var link = `https://compose.mail.yahoo.com/?subject=check+out+the+health+vitals&body=https://iterve.aivot.ai/vitals/pid%3D${parentid}/cid%3D${childid}`;
      } else {
        var link = `https://outlook.office.com/mail/deeplink/compose/?subject=check%20out%20the%20health%20vitals&body=https://iterve.aivot.ai/vitals/pid%3D${parentid}/cid%3D${childid}`;
      }
      window.open(link, "_blank");
    } catch (error) {
      console.error("Error:", error);
      // Handle error
    }
  };

  // const handleMailButtonClick = async () => {
  //   // Fetching the PDF file
  //   const blob = await pdf(<MyPDF healthParameters={healthParameters} formData={formData} />).toBlob();
  //   console.log('blob', blob);
  //   // Converting the PDF file to base64
  //   const reader = new FileReader();
  //   reader.readAsDataURL(blob);
  //   reader.onloadend = () => {
  //       const base64data = reader.result.split(',')[1];

  //       // Generate mailto link
  //       const recipient = 'recipient@gmail.com';
  //       const subject = 'Your Subject';
  //       const body = 'Please find the attached PDF.';
  //       const mailtoUrl = `mailto:${recipient}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}&attachment=${encodeURIComponent(base64data)}`;

  //       // Open mail app
  //       console.log(mailtoUrl);
  //       window.open(mailtoUrl, '_blank');
  //   };
  // };

  const copyToClipboard = async (text) => {
    console.log(text);
    await navigator.clipboard
      .writeText(text)
      .then(() => {
        setcopycompleted(true);
        setTimeout(() => {
          setcopycompleted(false);
        }, 3000);
        toast.success("Text copied to clipboard!", {
          autoClose: 1500,
        });
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  const handleWhatsappClick = async (s) => {
    //whatsapp share

    const sharelink = `https://${s}.whatsapp.com/send?text=https://iterve.aivot.ai/vitals/pid=${parentid}/cid=${childid}`;
    // const a = document.createElement('a');
    // a.href=sharelink;
    // a.click();
    window.open(sharelink, "_blank");
  };

  function formatNumber(num) {
    // Check if the number is an integer
    if (Number.isInteger(num)) {
      return num;
    } else {
      // Format the number to 2 decimal places
      return num.toFixed(2);
    }
  }

  const vitalsTableData = vitals
    ? [
        {
          name: "Vata",
          valueX: Math.round(parseFloat(vitals["vata"])),
          valueY: vitals["vatabalance"],
        },
        {
          name: "Pitta",
          valueX: Math.round(parseFloat(vitals["pitta"])),
          valueY: vitals["pittabalance"],
        },
        {
          name: "Kapha",
          valueX: Math.round(parseFloat(vitals["kapha"])),
          valueY: vitals["kaphabalance"],
        },
      ]
    : [];

  return (
    <div className={classes.container}>
      {/* <img src={watermark} className={classes.watermark} /> */}
      <div className={classes.headingRow}>
        <div style={{ width: "15vw" }}>
          <img
            src={HiimsLogo}
            alt="Hiims Logo here"
            className={
              accountNumber
                ? accountNumber.startsWith("Hiims")
                  ? classes.logo1
                  : classes.logo1VisOff
                : classes.logo1VisOff
            }
          />
        </div>
        <div className={classes.headingText}>Your Vitals</div>
        <div style={{ width: "15vw" }}>
          <img src={aivotLogo} alt="Aivot Logo here" className={classes.logo} />
        </div>
      </div>
      <div className={classes.infoSec}>
        <div
          className={classes.name}
          style={{
            borderTop: "0.1vw solid black",
            borderLeft: "0.1vw solid black",
          }}
        >
          <div style={{ padding: "0 2vw" }}>Name:</div>

          <div>{formData.name}</div>
        </div>

        <div
          className={classes.email}
          style={{
            borderTop: "0.1vw solid black",
            borderLeft: "0.1vw solid black",
            borderRight: "0.1vw solid black",
          }}
        >
          <div style={{ padding: "0 2vw" }}>Email:</div>
          <div>{formData.email}</div>
        </div>
      </div>

      <div className={classes.infoSec}>
        <div
          className={classes.phoneNumber}
          style={{
            borderTop: "0.1vw solid black",
            borderLeft: "0.1vw solid black",
          }}
        >
          <div>PhoneNumber</div>
        </div>

        <div
          className={classes.subject}
          style={{
            borderTop: "0.1vw solid black",
            borderLeft: "0.1vw solid black",
          }}
        >
          <div>Subject ID</div>
        </div>

        <div
          className={classes.gender}
          style={{
            borderTop: "0.1vw solid black",
            borderLeft: "0.1vw solid black",
          }}
        >
          <div>Gender</div>
        </div>

        <div
          className={classes.age}
          style={{
            borderTop: "0.1vw solid black",
            borderLeft: "0.1vw solid black",
          }}
        >
          <div>Age</div>
        </div>

        <div
          className={classes.height}
          style={{
            borderTop: "0.1vw solid black",
            borderLeft: "0.1vw solid black",
          }}
        >
          <div>Height</div>
        </div>

        <div
          className={classes.weight}
          style={{
            borderTop: "0.1vw solid black",
            borderLeft: "0.1vw solid black",
            borderRight: "0.1vw solid black",
          }}
        >
          <div>Weight</div>
        </div>
      </div>
      <div className={classes.infoSec}>
        <div
          className={classes.phoneNumber}
          style={{
            borderTop: "0.1vw solid black",
            borderLeft: "0.1vw solid black",
            borderBottom: "0.1vw solid black",
          }}
        >
          <div>{formData.phoneNumber} </div>
        </div>

        <div
          className={classes.subject}
          style={{
            borderTop: "0.1vw solid black",
            borderLeft: "0.1vw solid black",
            borderBottom: "0.1vw solid black",
          }}
        >
          <div>{formData.subjectid} </div>
        </div>

        <div
          className={classes.gender}
          style={{
            borderTop: "0.1vw solid black",
            borderLeft: "0.1vw solid black",
            borderBottom: "0.1vw solid black",
          }}
        >
          <div>{formData.gender}</div>
        </div>

        <div
          className={classes.age}
          style={{
            borderTop: "0.1vw solid black",
            borderLeft: "0.1vw solid black",
            borderBottom: "0.1vw solid black",
          }}
        >
          <div>{formData.age}</div>
        </div>

        <div
          className={classes.height}
          style={{
            borderTop: "0.1vw solid black",
            borderLeft: "0.1vw solid black",
            borderBottom: "0.1vw solid black",
          }}
        >
          <div>{formData.height}</div>
        </div>

        <div
          className={classes.weight}
          style={{
            borderTop: "0.1vw solid black",
            borderLeft: "0.1vw solid black",
            borderRight: "0.1vw solid black",
            borderBottom: "0.1vw solid black",
          }}
        >
          <div>{formData.weight}</div>
        </div>
      </div>

      <div className={classes.vitalRows}>
        <div className={classes.vitalsCol}>
          <div className={classes.vital}>
            <div
              style={{ fontSize: "2vw", color: "black", fontWeight: "bold" }}
            >
              Vitals
            </div>
          </div>
          <div className={classes.value}>
            <div
              style={{ fontSize: "2vw", color: "black", fontWeight: "bold" }}
            >
              Values
            </div>
          </div>
          <div className={classes.units}>
            <div
              style={{ fontSize: "2vw", color: "black", fontWeight: "bold" }}
            >
              Units
            </div>
          </div>
          <div className={classes.range}>
            <div
              style={{ fontSize: "2vw", color: "black", fontWeight: "bold" }}
            >
              Reference Range
            </div>
          </div>
        </div>

        {healthParameters.map((param, index) => (
          <div className={classes.vitalsCol} key={index}>
            <div className={classes.vital}>
              <div>{param.vital}</div>
            </div>
            <div className={classes.value}>
              <div>{param.value}</div>
            </div>
            <div className={classes.units}>
              <div>{param.unit}</div>
            </div>
            <div className={classes.range}>
              <div>{param.referenceRange}</div>
            </div>
          </div>
        ))}
      </div>

      <div className={classes.tableheading}>Ayurvedic Tridoshas</div>
      {vitals && (
        <table className={classes.vitalsTable}>
          <thead>
            <tr>
              <th>Dosha</th>
              <th>Prakruti</th>
              <th>Vikruti</th>
            </tr>
          </thead>
          <tbody>
            {vitalsTableData.map((row, index) => (
              <tr key={index}>
                <td>{row.name}</td>
                <td>{row.valueX}</td>
                <td>{row.valueY}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}

      <div className={classes.tableheading}>Food Recommendations</div>
      {vitals && (
        <table className={classes.vitalsTable}>
          <thead>
            <tr>
              <th>Food Type</th>
              <th>Eat</th>
              <th>Avoid</th>
              <th>Occasional</th>
            </tr>
          </thead>
          <tbody>
            {mealrecommended?.Eat &&
              Object.keys(mealrecommended.Eat).map((foodType, index) => (
                <tr key={index}>
                  <td>{foodType}</td>
                  <td>{mealrecommended.Eat[foodType]?.join(", ") || ""}</td>
                  <td>{mealrecommended.Avoid?.[foodType]?.join(", ") || ""}</td>
                  <td>
                    {mealrecommended.Occasionally?.[foodType]?.join(", ") || ""}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      )}

      <div className={classes.noteSec}>
        <div>
          <b>Note:</b> These values are only indicative, cannot be used for
          clinical. The vitals marked as <b>* (asterisk)</b> are under
          evaluation for testing the models. Occasionally, defined as 3-4 times
          a month, please do not consume any solid food after 7 p.m.
        </div>
      </div>

      <div className={classes.allbuttons}>
        <button
          className={classes.downloadBtn}
          onClick={() => {
            navigate("/reports1");
          }}
        >
          Home
        </button>

        <PDFDownloadLink
          document={
            <MyPDF
              healthParameters={healthParameters}
              formData={formData}
              accno={accountNumber}
              doshaParameters={doshaParameters}
              mealrecommended={mealrecommended}
            />
          }
          fileName={`${vitals ? vitals["name"] : ""}_report.pdf`}
          className={classes.downloadBtn}
        >
          {({ loading }) => (loading ? "Loading..." : "Download now")}
        </PDFDownloadLink>

        <div className={classes.popparent}>
          {sharepopup ? (
            <>
              <div className={classes.sharepopup}>
                <img
                  src={cross}
                  className={classes.crossbtn}
                  onClick={() => setsharepopup(false)}
                />
                <div className={classes.largecont}>
                  {!whatsappopt ? (
                    <button
                      className={
                        !mailopt ? classes.sharebtn : classes.sharebtn2
                      }
                      onClick={() => {
                        setmailopt(!mailopt);
                        return false;
                      }}
                    >
                      <img src={mailimage} className={classes.wpimage}></img>
                    </button>
                  ) : (
                    <></>
                  )}
                  {mailopt ? (
                    <>
                      <div className={classes.expandArrow}></div>
                      <div className={classes.wpoptdiv}>
                        <button
                          className={classes.wpoptbtn}
                          onClick={() => handleMailButtonClick("gmail")}
                        >
                          <img src={gamil} className={classes.optimage}></img>
                        </button>
                        <button
                          className={classes.wpoptbtn}
                          onClick={() => handleMailButtonClick("yahoo")}
                        >
                          <img src={yahoo} className={classes.optimage}></img>
                        </button>
                        <button
                          className={classes.wpoptbtn}
                          onClick={() => handleMailButtonClick("outlook")}
                        >
                          <img src={outlook} className={classes.optimage}></img>
                        </button>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                  {!mailopt && (
                    <div className={classes.wpdiv}>
                      {!whatsappopt ? (
                        <button
                          className={classes.sharebtn}
                          onClick={() => {
                            setwhatsappopt(!whatsappopt);
                          }}
                          style={{ backgroundImage: { wpimage } }}
                        >
                          <img className={classes.wpimage} src={wpimage}></img>
                        </button>
                      ) : (
                        <button
                          className={classes.sharebtn2}
                          onClick={() => {
                            setwhatsappopt(!whatsappopt);
                          }}
                          style={{ backgroundImage: { wpimage } }}
                        >
                          <img className={classes.wpimage} src={wpimage}></img>
                        </button>
                      )}
                      {whatsappopt ? (
                        <>
                          <div className={classes.expandArrow}></div>
                          <div className={classes.wpoptdiv}>
                            <button
                              className={classes.wpoptbtn}
                              onClick={() => handleWhatsappClick("web")}
                            >
                              <img
                                src={webimage}
                                className={classes.optimage}
                              ></img>
                            </button>
                            <button
                              className={classes.wpoptbtn}
                              onClick={() => handleWhatsappClick("api")}
                            >
                              <img
                                src={androidimage}
                                className={classes.optimage}
                              ></img>
                            </button>
                            <button
                              className={classes.wpoptbtn}
                              onClick={() => handleWhatsappClick("api")}
                            >
                              <img
                                src={appleimage}
                                className={classes.optimage}
                              ></img>
                            </button>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  )}
                </div>
                <div className={classes.smallcont}>
                  <div
                    className={classes.copybtn}
                    onClick={() =>
                      copyToClipboard(
                        `https://iterve.aivot.ai/doshavitals/pid=${parentid}/cid=${childid}`
                      )
                    }
                  >
                    <img
                      src={copycompleted ? tickimage : copyimage}
                      className={classes.copyimage}
                    ></img>
                    {`Copy`}
                  </div>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
          <button
            className={classes.downloadBtn}
            onClick={() => {
              setsharepopup(!sharepopup);
            }}
          >
            Share
          </button>
        </div>
      </div>
    </div>
  );
};

export default ReportVitals;
