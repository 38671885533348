import React, { useState, useEffect, useRef } from "react";
import Webcam from "react-webcam";
import { Camera } from "react-camera-pro-with-torch";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import classes from "./WebcamRecorder.module.css";
import extractRGBs from "./selftestUtil.js";
import * as faceLandmarksDetection from "@tensorflow-models/face-landmarks-detection";
import "@tensorflow/tfjs-core";
import "@tensorflow/tfjs-backend-webgl";
import "@mediapipe/face_mesh";
import { FaceMesh } from "@mediapipe/face_mesh";
import { updateInput } from "./formUtil";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

const generateCustomId = () => {
  const uuid = uuidv4();
  const letters = uuid
    .replace(/[^a-zA-Z]/g, "")
    .slice(0, 2)
    .toUpperCase(); // Get first two letters and convert to uppercase
  const digits = uuid.replace(/\D/g, "").slice(0, 4); // Remove non-digits and get the first 4 digits
  return `${letters}${digits}`;
};

function WebcamRecorder(props) {
  const [senddata, setsenddata] = useState({});
  const {
    startRecording,
    setRecording,
    token,
    isChecked,
    apiInput,
    isAlertActive,
    setAlertActive,
    alertWDC,
    progress,
    setProgress,
  } = props;

  const [cameraKey, setCameraKey] = useState(1);
  const [alertMessage, setAlertMessage] = useState();
  const navigate = useNavigate();
  const webcamRef = useRef();

  useEffect(() => setCameraKey(cameraKey + 1), [isAlertActive]);

  useEffect(() => {
    console.log("Video element", webcamRef.current);
    if (startRecording && isChecked) {
      setTimeout(() => {
        // if (webcamRef.current.flashStatus()) webcamRef.current.toggleTorch();
        frameExtract();
      }, 2000);
    }
  }, [startRecording]);

  function predictAndSave(red, green, blue) {
    const apiInputObject = {};

    // Iterate over apiInput (assuming it already has some key-value pairs)
    for (const [key, value] of apiInput.entries()) {
      apiInputObject[key] = value;
    }

    // Add new key-value pairs
    apiInputObject.red = red;
    apiInputObject.green = green;
    apiInputObject.blue = blue;
    apiInputObject.fps = red.length / 60;

    console.log(apiInputObject);
    const customId = generateCustomId();
    apiInputObject["subject_id"] = customId;
    // Send the combined data as JSON
    fetch("https://test.iterve.ai/api_a/process_data", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(apiInputObject),
    })
      .then((res) => {
        if (res.status !== 200) {
          throw new Error(
            `Request failed with status ${res.status}. ${res.json()}`
          );
        }
        return res.json();
      }) // If expecting JSON response
      .then((response_data) => {
        console.log(response_data);
        const accno = localStorage.getItem("accno");
        const vitalsData = {
          name: apiInputObject.name,
          email: apiInputObject.email,
          subjectid: apiInputObject["subject_id"],
          phoneNumber: apiInputObject.phoneNumber,
          diabetic: apiInputObject.Diabetic,
          activityFactor: apiInputObject.Activity_Factor,
          age: apiInputObject.age,
          gender: apiInputObject.gender == 1 ? "Male" : "Female",
          weight: apiInputObject.weight,
          height: apiInputObject.height,
          allowable_blood_loss: response_data["Average Blood Loss"],
          bmi: response_data["bmi"],
          body_fat: response_data["bfp"],
          body_water: response_data["tbw"],
          breathing_rate: response_data["Breathing Rate"],
          cardiac_index: response_data["Cardiac Index"],
          cardiac_output: response_data["Cardiac Output"],
          dbp: response_data["DBP"],
          hdl: response_data["HDL"],
          hba1c: response_data["HBA1C"],
          heart_rate: response_data["HR"],
          hematocrit: response_data["Haematocrit"],
          hemoglobin: response_data["Haemoglobin"],
          ibi: response_data["Mean IBI"],
          ldl: response_data["LDL"],
          mean_atrial_pressure: response_data["Mean Arterial Pressure"],
          mean_corpuscular_hemoglobin: response_data["MCHC"],
          mean_corpuscular_volume: response_data["MCV"],
          mean_plasma_glucose: response_data["ABG"],
          oxygen_saturation: response_data["SpO2"],
          pulse_pressure: response_data["Pulse Pressure"],
          pulse_rate_quotient: response_data["Pulse Rate Quotient"],
          rbc_count: response_data["RBC Count"],
          rmssd: response_data["RMSSD"],
          random_blood_sugar: response_data["random_blood_sugar"],
          sbp: response_data["SBP"],
          sd1: response_data["SD1"],
          sd2: response_data["SD2"],
          sdnn: response_data["SDNN"],
          stress_index: response_data["Stress Index Bravesky"],
          stroke_volume: response_data["Stroke Volume"],
          subcutaneous_fat: response_data["sf"],
          total_cholestrol: response_data["Total_Cholesterol"],
          triglycerides: response_data["Triglycerides"],
          vldl: response_data["VLDL"],
          visceral_fat: response_data["vf"],
          Amid: response_data["AMID"],
          Amp: response_data["Amplitude"],
          BSA: response_data["BSA"],
          bloodVolume: response_data["Blood Volume"],
          // New parameters
          DMID: response_data["DMID"],
          fattyLiverIndex: response_data["Fatty liver Index"],
          GGPP: response_data["GGPP"],
          GGPP_old: response_data["GGPP_old"],
          HF_power: response_data["HF power"],
          Hip: response_data["Hip"],
          Hip_Waist_Ratio: response_data["Hip_Waist_Ratio"],
          K_Value: response_data["K-Value"],
          Kurtosis: response_data["Kurtosis"],
          LDL_old: response_data["LDL_old"],
          LF_HF_Ratio: response_data["LF HF Ratio"],
          LF_power: response_data["LF power"],
          MCH: response_data["MCH"],
          PNS_Index: response_data["PNS Index"],
          RBC_Count_Old: response_data["RBC Count_Old"],
          SDSD: response_data["SDSD"],
          SGOT: response_data["SGOT"],
          SGOT_old: response_data["SGOT_old"],
          SGPT: response_data["SGPT"],
          SGPT_old: response_data["SGPT_old"],
          SNS_Index: response_data["SNS Index"],
          Skewness: response_data["Skewness"],
          Spectral_Energy: response_data["Spectral Energy"],
          Stress_Index_old: response_data["Stress Index Bravesky_old"],
          Stress_Index_us: response_data["Stress Index us"],
          Triglycerides_old: response_data["Triglycerides_old"],
          Waist: response_data["Waist"],
          age_sex_factor: response_data["age_sex_factor"],
          bfm: response_data["bfm"],
          bmr: response_data["bmr"],
          dcn: response_data["dcn"],
          ecgplot: response_data["ecgplot"],
          lbm: response_data["lbm"],
          mNPV: response_data["mNPV"],
          param1: response_data["param1"],
          param2: response_data["param2"],
          serumCreatinine: response_data["serum_creatine"],
          uricAcid: response_data["uric_acid"],
        };
        console.log("vitalsData", vitalsData);
        axios
          .post("https://iterve.aivot.ai/palmuser", vitalsData, {
            params: { accno },
          })
          .then((res) => {
            console.log(res);
          });
        navigate("/reportspalm", { state: vitalsData });
      })
      .catch((error) => alertWDC("Some noise detected, please retest"));
  }

  function frameExtract() {
    const canvas = document.getElementById("webcam-overlay");
    const context = canvas.getContext("2d");
    const video = document.getElementById("video");

    const width = canvas.width;
    const height = canvas.height;

    // Coordinates to get the center 100x100 pixels
    const centerX = Math.floor(width / 2) - 50;
    const centerY = Math.floor(height / 2) - 50;

    const timestamp = performance.now();
    const numPixels = 100 * 100;
    var iter = 0;

    var red = [],
      green = [],
      blue = [];

    const interval = setInterval(() => {
      iter++;
      context.drawImage(video, 0, 0, width, height);
      const pixelData = context.getImageData(centerX, centerY, 100, 100).data;
      if (iter % 30 == 0) setProgress((performance.now() - timestamp) / 600);
      let totalRed = 0,
        totalGreen = 0,
        totalBlue = 0;

      for (let i = 0; i < pixelData.length; i += 4) {
        totalRed += pixelData[i]; // Red channel
        totalGreen += pixelData[i + 1]; // Green channel
        totalBlue += pixelData[i + 2]; // Blue channel
      }

      const avgRed = totalRed / numPixels;
      const avgGreen = totalGreen / numPixels;
      const avgBlue = totalBlue / numPixels;
      if (avgRed >= 255) alertWDC("red spiked");
      var stopRecord =
        avgRed < 70 || avgRed / avgGreen < 1.5 || avgRed / avgBlue < 1.5;
      if (stopRecord) {
        clearInterval(interval);
        setRecording(false);
        setAlertActive(true);
        if (iter > 30) alert("surface movement detected");
        else alert("The palm is not properly(too dark or too whitewashed) lit");
        setProgress(0);
        setTimeout(() => setAlertActive(false), 1000);
      }
      red.push(avgRed);
      green.push(3 * avgGreen);
      blue.push(3 * avgBlue);

      if (performance.now() - timestamp > 60000) {
        clearInterval(interval);
        setRecording(false);
        predictAndSave(red, green, blue);
      }
    }, 28);
  }

  return (
    <div className={classes.column}>
      {/* {alertMessage? (
        <div
          style={{
            padding: "16px",
            backgroundColor: "#ffcc00",
            color: "#000",
            borderRadius: "4px",
          }}
        >
          {alertMessage}
        </div>
      ): */}
      {!isAlertActive && (
        <div className={classes.webcamrecorder}>
          <div className={classes.circularframe} id="webcam-container">
            <canvas
              className={classes.webcamOverlay}
              id="webcam-overlay"
              willReadFrequently={true}
            ></canvas>
            {/* <Webcam
            audio={false}
            videoConstraints={{
              facingMode: { exact: "environment" }
              
            }}
            ref={webcamRef}
            screenshotFormat="image/jpeg"
            className={classes.webcam}
            mirrored
          /> */}
            <Camera ref={webcamRef} facingMode={"environment"} />
          </div>
          <div className={classes.progressbar}>
            <CircularProgressbar
              value={progress}
              styles={buildStyles({
                rotation: 0,
                strokeLinecap: "butt",
                pathColor: "rgba(201, 124, 229, 1)",
              })}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default WebcamRecorder;
