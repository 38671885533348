import React from "react";
import classes from "./navbar.module.css";
import apolloLogo from "../Images/apolloLogo.png";
import aivotLogo from "../Images/aivotLogo.png";

const Navbar = () => {
  return (
    <div className={classes.header}>
      {/* <img src={apolloLogo} alt="apolloLogo" className={classes.apolloLogo} /> */}
      <img src={aivotLogo} alt="aivotLogo" className={classes.aivotLogo} />
    </div>
  );
};

export default Navbar;
