export const updateInput = (e = null) => {
  if (e) e.preventDefault();

  const updatedFormData = {
    name: document.getElementById("name").value,
    email:
      document.getElementById("email").value === ""
        ? ""
        : document.getElementById("email").value,
    phoneNumber: document.getElementById("phoneNumber").value,
    age: document.getElementById("age").value,
    weight: document.getElementById("weight").value,
    height: document.getElementById("height").value,
    gender: document.getElementById("gender").value,
    activity_factor: document.getElementById("activityFactor").value,
    rbs_fbs: document.getElementById("rbs_fbs").value, // Updated for new field
  };

  if (document.getElementById("sbp"))
    updatedFormData["sbp"] = document.getElementById("sbp").value;
  if (document.getElementById("dbp"))
    updatedFormData["dbp"] = document.getElementById("dbp").value;

  if (validateForm1(updatedFormData)) {
    const formData = new FormData();
    Object.entries(updatedFormData).map(([key, value]) => {
      formData.append(key, value);
    });
    return formData;
  }
  return false;
};

export const validateForm1 = (formData) => {
  // Regular expressions for validation
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const mobileRegex = /^\d{10}$/;
  const ageRegex = /^\d+$/;
  const weightRegex = /^\d+(\.\d+)?$/;
  const heightRegex = /^\d+(\.\d+)?$/;

  // Validate each field
  if (!formData.name.trim()) {
    alert("Please enter a name.");
    return false;
  }

  if (formData.email !== "" && !emailRegex.test(formData.email)) {
    alert("Please enter a valid email address.", formData.email);
    return false;
  }
  if (formData.email === "" && document.getElementById("email").required) {
    alert("Please enter a valid email address.", formData.email);
    return false;
  }

  if (!mobileRegex.test(formData.phoneNumber)) {
    alert("Please enter a valid 10-digit mobile number.");
    return false;
  }

  if (!ageRegex.test(formData.age)) {
    alert("Please enter a valid age.");
    return false;
  }

  if (!weightRegex.test(formData.weight)) {
    alert("Please enter a valid weight.");
    return false;
  }

  if (!heightRegex.test(formData.height)) {
    alert("Please enter a valid height.");
    return false;
  }

  // if(parseFloat(formData.height)>2.2 || parseFloat(formData.height)<1) {
  //   alert("Please enter a valid height(in meter). ");
  //   return false;
  // }

  // Validate gender
  if (formData.gender !== "Male" && formData.gender !== "Female") {
    alert("Please select a valid gender (Male/Female).");
    return false;
  }

  // Validate diabetic
  // if (formData.diabetic !== "yes" && formData.diabetic !== "no") {
  //   alert("Please select a valid option for diabetic (yes/no).");
  //   return false;
  // }

  // Validate new fields
  const rbsFbs = parseFloat(formData.rbs_fbs);
  if (isNaN(rbsFbs) || rbsFbs <= 0) {
    alert("Please enter a valid RBS/FBS value.");
    return false;
  }
  console.log("sbp", !formData.sbp, "dbp", formData.dbp)
  if (formData.sbp !== undefined) {
    const sbp = parseFloat(formData.sbp);
    console.log(sbp)
    if (isNaN(sbp) || sbp <= 0) {
      alert("Please enter a valid SBP value.");
      return false;
    }
  }

  if (formData.dbp !==undefined) {
    const dbp = parseFloat(formData.dbp);
    console.log(dbp)
    if (isNaN(dbp) || dbp <= 0) {
      alert("Please enter a valid DBP value.");
      return false;
    }
  }

  return true; // Form is valid
};

export const validateForm2 = (formData) => {
  const weightChanged = document.getElementById("weightChanged").value;
  const mealTimeElapsed = document.getElementById("mealTimeElapsed").value;
  const hypertension = document.getElementById("hypertension").value;
  const diabetic = document.getElementById("diabetic").value;
  const healthIssues = document.getElementById("healthIssues").value;

  // Check each field separately
  if (weightChanged === "") {
    console.log("Please select if recent weight changed or not");
    return null;
  }

  if (mealTimeElapsed === "") {
    console.log("Please select meal time elapsed");
    return null;
  }

  if (hypertension === "") {
    console.log("Please select if you have hypertension or not");
    return null;
  }

  if (diabetic === "") {
    console.log("Please select if you are diabetic or not");
    return null;
  }

  if (healthIssues === "") {
    console.log("Please select if you have any major health issues or not");
    return null;
  }

  // Append values to formData
  formData.append("weightChanged", weightChanged);
  formData.append("mealTimeElapsed", mealTimeElapsed);
  formData.append("hypertension", hypertension);
  formData.append("diabetic", diabetic);
  formData.append("healthIssues", healthIssues);

  return formData;
};

export function familyOptionsToBinaryString(options) {
  let binaryString = "000";
  console.log(options);
  if (options.includes("Father")) binaryString = "100";
  if (options.includes("Mother"))
    binaryString = binaryString.substring(0, 1) + "10";
  if (options.includes("Siblings"))
    binaryString = binaryString.substring(0, 2) + "1";
  return binaryString;
}

export function medicationOptionsToBinaryString(options) {
  console.log(options);
  let binaryString = "0000";
  if (options.includes("Diabetes")) binaryString = "1000";
  if (options.includes("Thryroid"))
    binaryString =
      binaryString.substring(0, 1) + "1" + binaryString.substring(2);
  if (options.includes("BP"))
    binaryString =
      binaryString.substring(0, 2) + "1" + binaryString.substring(3);
  if (options.includes("Cholestrol"))
    binaryString = binaryString.substring(0, 3) + "1";
  return binaryString;
}
